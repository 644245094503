import axios from 'axios';
import { domain } from './domain';

export const getMajors = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
        },
        data,
    };
    return axios(domain + '/nutritionist/get-majors', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateNutritionist = (token,data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/nutritionist/user', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getNutritionist = (token,data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/nutritionist/getfromadmin', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const uploadPhoto = (token,data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json', 
            'Authorization' : 'Bearer ' + token
        },
        data,
    };
    return axios(domain + '/nutritionist/gallery', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const deleteNutritionInstance =  (token,data) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-type': 'application/json',
            "Authorization" : "Bearer " + token
        },
        data
    };
    return axios(domain + '/daily', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getUserNutritionInstance =  (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            "Authorization" : "Bearer " + token
        },
        data
    };
    return axios(domain + '/daily/getnut', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};